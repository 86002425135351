import axios from "axios";
import { IClientProspectConvertToClient } from "pages/Cliente/models/client-prospect.model";
import appConfiguration from "./appConfiguration";

export const axiosInstance = axios.create({
    baseURL: appConfiguration.API.URL
});
const api = {
    testToken: {
        getClientsSearch: (): Promise<any> =>
            axiosInstance.get(`Api/Authorize`)
    },
    company: {
        getCompany: (): Promise<any> =>
            axiosInstance.get(`users/information`),
        getAllCompanies: (controller: AbortSignal) =>
            axiosInstance.get('types/enterprises',
                { signal: controller })
    },
    cliente: {
        validate: (clientId:any): Promise<any> =>{
            return axiosInstance.get(`clients/${clientId}/verificate`)
        },
        getClientsSearch: (documentIdentityNumber = '', documentIdentityTypeId = ''): Promise<any> =>
            axiosInstance.get(`clients/search?documentIdentityNumber=${documentIdentityNumber}&documentIdentityTypeId=${documentIdentityTypeId}`)
        ,
        postClient: (data: any = {}): Promise<any> =>
            axiosInstance.post('clients', data)
        ,
        patchtClient: (data: any = {}): Promise<any> =>
            axiosInstance.patch('clients', data)
        ,
        getClienteId: (id: number, controller: AbortSignal): Promise<any> =>
            axiosInstance.get(`clients/${id}`, { signal: controller })
        ,
        putClientBusiness: (clientId: any, clientBusinessTypeId: any): Promise<any> =>
            axiosInstance.put(`clients/${clientId}/businessType/${clientBusinessTypeId}`)
        ,
        getImgLink: (clientId: any): Promise<any> =>
            axiosInstance.get(`clients/${clientId}/consentImage`, {
                responseType: "blob",
            })
    },
    clientUif: {
        getClientUif: (id: any): Promise<any> =>
            axiosInstance.get(`clients/uif/${id}`)
        ,
        getUIFAddress: (id: any, controller: AbortSignal): Promise<any> =>
            axiosInstance.get(`clients/${id}/uif/address`, { signal: controller })
        ,
        deleteUIFAddress: (clientId: any, clientAddressUifId: any, controller: AbortSignal): Promise<any> =>
            axiosInstance.delete(`clients/${clientId}/uif/address/${clientAddressUifId}`, { signal: controller })
        ,
        postClientUif: (body: any): Promise<any> =>
            axiosInstance.post(`clients/uif`, body)
        ,
        putClientUif: (body: any): Promise<any> =>
            axiosInstance.put(`clients/uif`, body),
        saveUifAddress: (body: any, controller: AbortSignal): Promise<any> =>
            axiosInstance.post(`clients/uif/address`, body, { signal: controller }),
        updateUifAddress: (body: any, controller: AbortSignal): Promise<any> =>
            axiosInstance.put(`clients/uif/address`, body, { signal: controller })
    },
    clientAddress: {
        getClientAddress: (clientId: any, controller: AbortSignal): Promise<any> =>
            axiosInstance.get(`clients/${clientId}/addresses`, { signal: controller })
        ,
        getClientAddressPurposes: (controller: AbortSignal): Promise<any> =>
            axiosInstance.get('types/clientAddressPurposes', { signal: controller })
        ,
        saveAddress: (body: any, controller: AbortSignal): Promise<any> =>
            axiosInstance.post(`clients/addresses`, body, { signal: controller })
        ,
        updateAddress: (body: any, controller: AbortSignal): Promise<any> =>
            axiosInstance.put(`clients/addresses`, body, { signal: controller })
    },
    clientContact: {
        postClientContact: (body: any, controller: AbortSignal): Promise<any> =>
            axiosInstance.post(`clients/contacts`, body, { signal: controller })
        ,
        putClientContact: (body: any, controller: AbortSignal): Promise<any> =>
            axiosInstance.put(`clients/contacts`, body, { signal: controller })
        ,
        deleteClientContact: (clientId: any, contactId: any, controller: AbortSignal): Promise<any> =>
            axiosInstance.delete(`clients/${clientId}/contacts/${contactId}`, { signal: controller })
        ,
        getClientContact: (clientId: any, controller: AbortSignal): Promise<any> =>
            axiosInstance.get(`clients/${clientId}/contacts`, { signal: controller })
        ,
        getClientContactId: (clientId: any, contactId: any, controller: AbortSignal): Promise<any> =>
            axiosInstance.get(`clients/${clientId}/contacts/${contactId}`, { signal: controller })
    },
    types: {
        getDocumentIdentity: (options = {}): Promise<any> =>
            axiosInstance.get('types/documentIdentity')
        ,
        getClient: (options = {}): Promise<any> =>
            axiosInstance.get('types/client')
        ,
        getClienteBusinessType: (): Promise<any> =>
            axiosInstance.get('types/clientBusinessType')
        ,
        getClientUifPerson: (): Promise<any> =>
            axiosInstance.get('types/clientPerson')
        ,
        getClientUifEnterprise: (): Promise<any> =>
            axiosInstance.get('types/clientEnterprise')
        ,
        getClientUifRiskFactor: (): Promise<any> =>
            axiosInstance.get('types/clientRiskFactor')
        ,
        getClientUifMaritalStatus: (): Promise<any> =>
            axiosInstance.get('types/maritalStatus')
        ,
        getClientUifPersonOcupation: (): Promise<any> =>
            axiosInstance.get('types/clientPersonOcupations')
        ,
        getClientUifFundsSource: (): Promise<any> =>
            axiosInstance.get('types/clientFundsSources')
        ,
        getClientAddressPurposesUif: (controller: AbortSignal): Promise<any> =>
            axiosInstance.get('types/clientAddressPurposesUif', { signal: controller }),
        getStates: (controller: AbortSignal): Promise<any> =>
            axiosInstance.get('types/states', { signal: controller }),
        getProvinces: (stateId: number, controller: AbortSignal): Promise<any> =>
            axiosInstance.get(`types/provinces?stateCode=${stateId}`, { signal: controller }),
        getCities: (provinceId: number, controller: AbortSignal): Promise<any> =>
            axiosInstance.get(`types/cities?provinceCode=${provinceId}`, { signal: controller })
        ,
        getEmploymentJobTitles: (controller: AbortSignal): Promise<any> =>
            axiosInstance.get('types/clientEmploymentJobTitles', { signal: controller })
        ,
        getCountries: (controller: AbortSignal): Promise<any> =>
            axiosInstance.get('types/countries', { signal: controller })
        ,
    },
    ClientValidation: {
        postClientValidationSms: (body: any): Promise<any> =>
            axiosInstance.post('clients/validation/sms', body)
        ,
        postClientVerificateSms: (body: any): Promise<any> =>
            axiosInstance.post('clients/validation/sms/verificate', body)
        ,
        postClientValidationEmail: (body: any): Promise<any> =>
            axiosInstance.post('clients/validation/email', body)
        ,
        postClientValidationImg: (body: any): Promise<any> =>
            axiosInstance.post('clients/validation/img', body)
        ,
        getClientVerificateEmail: (token: any, accept: any) =>
            // @ts-ignore
            axios.get(`${window.API_URL}clients/validation/email/verificate?token=${token}&accept=${accept}`)
    },
    clientProspect: {
        convertToClient: (body: IClientProspectConvertToClient, controller: AbortSignal): Promise<any> =>
            axiosInstance.post(`clients/convertProspect`, body, { signal: controller }),
    }

}
export default api;