import { createSlice } from '@reduxjs/toolkit';
export const CurrentTabEmptyState = {
    currentTab: null
};
export const registerCurrentTabSlice = createSlice({
    name: 'register-current-tab',
    initialState: CurrentTabEmptyState,
    reducers: {
        setCurrentTab: (state: any, action: any) => ({ ...state, ...action.payload }),
        resetCurrentTab: () => CurrentTabEmptyState
    }
});
export const { setCurrentTab, resetCurrentTab } = registerCurrentTabSlice.actions;
export default registerCurrentTabSlice.reducer;