import Loader from 'components/elements/Loader/Loader';
import React, { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store from 'redux/store';
import DashboardRoutes from 'routes/Dashboard/DashboardRoutes';
import PrivateClientLpdp from './routes/Private-consentimient-lpdp/PrivateClienteLpdp';
import PrivateConsentiment from './routes/Private-consentimient-lpdp/PrivateConsentiment';

const Login = lazy(() => import('pages/Login/components/Login.component'));
const Home = lazy(() => import('pages/Home/Home'));
const Sistema = lazy(() => import('pages/Sistema/Sistema'));
const Registro = lazy(() => import('pages/Registro/Registro'));
const ClientLpdp = lazy(() => import('pages/Cliente/ClientLpdp'));
const ClienteLPDP = lazy(() => import('pages/clienteLPDP/ClienteLPDP'));
const CreateeditContact = lazy(() => import('pages/clienteLPDP/Contacto/CreateEditContact/CreateeditContact'));
const CreateditDirecction = lazy(() => import('pages/clienteLPDP/Uif/Createdit/CreateditDirecction'));
const ConsentimientoImg = lazy(() => import('pages/consentimiento/img/ConsentimientoImg'));
const ConsentiemientoSms = lazy(() => import('pages/consentimiento/sms/ConsentiemientoSms'));
const TipoNegocio = lazy(() => import('pages/tipo-negocio/Tiponegocio'));
const ClientEmailVerificate = lazy(() => import('pages/verificateEmail/components/ClientEmailVerificate'))

const App = () => {
  return (
    <React.StrictMode>
      {/* <AppContainer className="App"> */}
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Sistema />} />
              <Route path="/login" element={<Login />} />
              <Route path="/cliente/email/verificate" element={<ClientEmailVerificate />} />
              <Route path="/cliente" element={<DashboardRoutes navbar={true} />}>
                <Route index element={<ClientLpdp />} />
                <Route path="/cliente" element={<PrivateClientLpdp />}>
                  <Route path="/cliente/cliente-lpdp" element={<ClienteLPDP />} />
                  <Route path='/cliente/cliente-lpdp/:id' element={<CreateeditContact />} />
                  <Route path='/cliente/contacto' element={<CreateeditContact />} />
                  <Route path='/cliente/direccion' element={<CreateditDirecction />} />
                </Route>
              </Route>
              <Route path="/" element={<DashboardRoutes navbar={true} />}>
                <Route path="/" element={<PrivateConsentiment />}>
                  <Route path="/consentimiento/sms" element={<ConsentiemientoSms />} />
                  <Route path="/consentimiento/img" element={<ConsentimientoImg />} />
                </Route>
              </Route>
              <Route path="/inicio" element={<DashboardRoutes />} >
                <Route path="/inicio" element={<Home />} />
              </Route>
              <Route path="/consentimiento" element={<DashboardRoutes />} >
                <Route path="/consentimiento" element={<PrivateConsentiment />}>
                  <Route index element={<Registro />} />
                </Route>
              </Route>
              <Route path="/tipo-negocio" element={<DashboardRoutes navbar={true} />}>
                <Route path="/tipo-negocio" element={<PrivateConsentiment />}>
                  <Route index element={<TipoNegocio />} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </Provider>
      </Suspense>
      {/* </AppContainer> */}
    </React.StrictMode >
  );
};
export default App;