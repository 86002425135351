
import PrivateRouteClient from 'routes/RoutesPrivate/Private.route.client';


interface Props {
  navbar?: boolean;
  customerValidation?: boolean;
}
export const PrivateClientLpdp = () => {

  return (
    <>
      {/* {navbar && <Navbar />} */}
      {/* {customerValidation && <PirvateRoute/>} */}
      <PrivateRouteClient />
    </>
  )
}
export default PrivateClientLpdp