import styled from "styled-components";

export const ConatinerLoading = styled.div`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0.7;
    background-color: #fff;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SpinerText = styled.h5`
   position: absolute;
   bottom: 35%; 
   color: #005e96;
   font-size:18px;
   font-weight: 600;

   @media (max-width: 768px) {
        bottom: 37%; 
    }
`;

export const BoxSvg = styled.div`

@keyframes ldio-tyjrttcyoj {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-tyjrttcyoj div {
    left: 103.075px;
    top: 50.995px;
    position: absolute;
    animation: ldio-tyjrttcyoj linear 1.1363636363636365s infinite;
    background: #005e96;
    width: 10.85px;
    height: 32.55px;
    border-radius: 5.425px / 6.51px;
    transform-origin: 5.425px 57.504999999999995px;
  }.ldio-tyjrttcyoj div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.0416666666666665s;
    background: #005e96;
  }.ldio-tyjrttcyoj div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.9469696969696969s;
    background: #005e96;
  }.ldio-tyjrttcyoj div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.8522727272727272s;
    background: #005e96;
  }.ldio-tyjrttcyoj div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.7575757575757576s;
    background: #005e96;
  }.ldio-tyjrttcyoj div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.6628787878787878s;
    background: #005e96;
  }.ldio-tyjrttcyoj div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5681818181818181s;
    background: #005e96;
  }.ldio-tyjrttcyoj div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.47348484848484845s;
    background: #005e96;
  }.ldio-tyjrttcyoj div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3787878787878788s;
    background: #005e96;
  }.ldio-tyjrttcyoj div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.28409090909090906s;
    background: #005e96;
  }.ldio-tyjrttcyoj div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.1893939393939394s;
    background: #005e96;
  }.ldio-tyjrttcyoj div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.0946969696969697s;
    background: #005e96;
  }.ldio-tyjrttcyoj div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #005e96;
  }
  &.loadingio-spinner-spinner-wkj07u6mc5o {
    width: 217px;
    height: 217px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
  }
  .ldio-tyjrttcyoj {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-tyjrttcyoj div { box-sizing: content-box; }
  /* generated by https://loading.io/ */

`