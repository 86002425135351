import { RouteGuard } from 'components';
import React from 'react';
import { NavbarComponent } from 'shared/Navbar';
interface Props {
  navbar?: boolean;
}
export const DashboardRoutes = ({ navbar = false }: Props) => {
  return (
    <>
      {navbar && <NavbarComponent />}
      <RouteGuard />
    </>
  )
}
export default DashboardRoutes