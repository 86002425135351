export const MAIN_COLOR = '#005E96';
export const MAIN_COLOR2 = '#18b7df';
export const MAIN_COLOR_INPUT = '#60A9D5';
export const PRINCIPAL_COLOR_TEXT = '#0146C9';
export const PRINCIPAL_GRAY_TEXT = '#727689';

export const PRINCIPAL_ICONS_COLOR = '#49D2B1';

export const BLACK_COLOR_TEXT = '#1B2B50';

export const PRINCIPAL_GRAY_COLOR = '#0075BC';

export const PRINCIPAL_GRAY_COLOR_2 = '#999191';

export const SOFT_BLUE_COLOR = '#E4EFFC';

export const BG_APP = '#F6F6F6';

export const BG_GENERAL = '#E5E5E5';

export const TEXT_PRIMARY = '#4944FD';

export const TEXT_DARK = '#1B2B50';

export const TEXT_MUTED = '#8A95CA';

export const TEXT_ACTIVE = '#006FDC';
