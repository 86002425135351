import { User } from 'models';
import { createSlice } from '@reduxjs/toolkit';

export const UserEmptyState: User = {
  name: '',
  token: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState: UserEmptyState,
  reducers: {
    createLogin: (state, action) => action.payload,
    modifyLogin: (state, action) => ({ ...state, ...action.payload }),
    resetUser: () => UserEmptyState
  }
});

export const { createLogin, modifyLogin, resetUser } = userSlice.actions;

export default userSlice.reducer;
