import { createSlice } from '@reduxjs/toolkit';
export interface ClientRegisterState {
    documentNumber: string,
    isRuc10: boolean,
    isRuc20: boolean,
    addresses: any[]
}
export const CustomerRegisterEmptyState: ClientRegisterState = {
    documentNumber: "",
    isRuc10: false,
    isRuc20: false,
    addresses: []
};
export const customerRegisterSlice = createSlice({
    name: 'customer-register',
    initialState: CustomerRegisterEmptyState,
    reducers: {
        createCustomerRegister: (state, action) => action.payload,
        updateCustomerRegister: (state: any, action: any) => ({ ...state, ...action.payload }),
        resetCustomerRegister: () => CustomerRegisterEmptyState
    }
});
export const { createCustomerRegister, updateCustomerRegister, resetCustomerRegister } = customerRegisterSlice.actions;
export default customerRegisterSlice.reducer;