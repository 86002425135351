import { configureStore } from '@reduxjs/toolkit';
import { companySlice } from './states/company-state';
import { customerRegisterSlice } from './states/customerRegister';
import { registerCurrentTabSlice } from './states/registerCurrenyTab';
import { registerUserSlice } from './states/registerUser';
import { userSlice } from './states/user';
export interface AppStore {
  user: any;
  registerUser: any;
  registerCurrentTab: any;
  customerRegister: any;
  company: any;
}
export default configureStore<AppStore>({
  reducer: {
    user: userSlice.reducer,
    registerUser: registerUserSlice.reducer,
    registerCurrentTab: registerCurrentTabSlice.reducer,
    customerRegister: customerRegisterSlice.reducer,
    company: companySlice.reducer
  }
});