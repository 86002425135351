// import React from 'react'
import section from 'assest/svg/logo-clientLpdp-desktop.svg';
import sectionMobile from 'assest/svg/logo-clientLpdp-mobile.svg';
import iconHamburguesa from 'assest/svg/Icon-hambuguesa.svg';
import { MAIN_COLOR } from '../components/constants';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown, Col, Row, Offcanvas } from "react-bootstrap";
import { NavBar } from "./styles"
import { useAccount, useMsal } from "@azure/msal-react";
import appConfiguration from 'providers/appConfiguration';
import { useEffect, useState } from 'react';
import { useFetchAndLoad } from 'hooks';
import useShowAlert from 'hooks/useShowAlert/useShowAlert';
import { getAllCompanies } from 'services/client-shared.service';

export const NavbarComponent = () => {
    const fakeUser = "Usuario Prueba";
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { accounts, instance } = useMsal();
    const { callEndpoint } = useFetchAndLoad();
    const [companies, setCompanies] = useState([]);
    const [currentCompany, setCurrentCompany] = useState('');
    const navigate = useNavigate();
    const account = useAccount(accounts[0] || {});
    const { showError, showLoading } = useShowAlert();
    const logout = () => {
        localStorage.clear();
        if (appConfiguration.LocalLogin.active) {
            navigate("/")
            return;
        }
        instance.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        });
    }
    const setNewCurrentCompany = (newCompany: string) => {
        if (localStorage.getItem('company')) {
            const companyItem = { ...JSON.parse(localStorage.getItem('company') || ''), company: newCompany };
            localStorage.setItem("company", JSON.stringify(companyItem));
        }
        setCurrentCompany(newCompany)
        setShow(false)
    }
    useEffect(() => {
        const getCurrentCompany = () => {
            const companyItem = localStorage.getItem('company');
            if (companyItem) {
                const companyParse = JSON.parse(companyItem);
                setCurrentCompany(companyParse.company)
            }
        }
        const loadCompanies = async () => {
            try {
                const response = await callEndpoint(getAllCompanies());
                setCompanies(response.data.data);
            } catch (error) {
                showError(error);
                showLoading(false);
            } finally {
                showLoading(false);
            }
        }
        getCurrentCompany();
        loadCompanies();
    }, [])

    return (
        <>
            <nav className="navbar navbar-light" style={{ background: MAIN_COLOR }}>
                <div className="container-fluid p-1 mx-md-5 mx-3">
                    <Link className="navbar-brand d-none d-md-block" to='/cliente'>
                        <img src={section} alt="" className="d-inline-block align-text-top" />
                    </Link>
                    <Link className="navbar-brand  d-md-none" to='/cliente'>
                        <img src={sectionMobile} alt="" className="d-inline-block align-text-top" />
                    </Link>

                    <div className='d-flex'>
                        <NavBar expand={false} >
                            <Container>
                                <Navbar.Text className="text-center" style={{ color: 'white' }}>
                                    {appConfiguration.LocalLogin.active ?
                                        fakeUser :
                                        account?.name}
                                </Navbar.Text>
                                <Navbar.Toggle onClick={() => handleShow()} aria-controls={`offcanvasNavbar-expand-xxl`} />
                                <Navbar.Offcanvas
                                    id={`offcanvasNavbar-expand-xxl`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-xxl`}
                                    show={show}
                                    onHide={handleClose}
                                    placement="end">
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xxl`}>
                                            {appConfiguration.LocalLogin.active ?
                                                fakeUser :
                                                account?.name} | {currentCompany}
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav className="text-center justify-content-end flex-grow-1 pe-3">
                                            <NavDropdown.Divider />
                                            <NavDropdown
                                                title={currentCompany}
                                                id={`offcanvasNavbarDropdown-expand-xxl`}>
                                                {companies.map(company =>
                                                    <NavDropdown.Item className="text-center navbar-text" key={`nav-${company}`} onClick={() => setNewCurrentCompany(company)}>
                                                        {company}
                                                    </NavDropdown.Item>
                                                )}
                                            </NavDropdown>
                                            <NavDropdown.Divider />
                                            <Nav.Link onClick={() => logout()}>
                                                Salir
                                            </Nav.Link>
                                        </Nav>
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                            </Container>
                        </NavBar>
                    </div>
                </div>
            </nav>
        </>
    )
}